




































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Ordenanzas extends Vue {
  private win = window;
  private ordenanzas: any = null;
  private selected: any = null;
  private verModal: boolean = false;
  private menu = "";
  private menuLateral: any = [];

  private mounted() {
    document.title = "Ordenanzas - Municipalidad de Quilpué";
    this.setMenu();
    this.getOrdenanzas();
  }

  private verDetalle(item: any) {
    this.selected = item;
    this.verModal = true;
  }
  private getOrdenanzas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("ordenanzas")
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.ordenanzas = res.data;
        this.ordenanzas = res.data;
        for (let i = 0; i < this.ordenanzas.length; i++) {
          if (this.ordenanzas[i].archivo && this.ordenanzas[i].archivo != "") {
            this.ordenanzas[i].archivo = base + this.ordenanzas[i].archivo;
          } else {
            this.ordenanzas[i].archivo = "#";
          }
          if (
            this.ordenanzas[i].archivo_modifica &&
            this.ordenanzas[i].archivo_modifica != ""
          ) {
            this.ordenanzas[i].archivo_modifica =
              base + this.ordenanzas[i].archivo_modifica;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "ordenanzas-municipales", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(item: any) {
    if (
      item.nombre_web == "ordenanzas" ||
      "ordenanzas-municipales" == item.nombre_web
    ) {
      item.expand = true;
      return " background-color: #f5cd0b !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
    } else {
      let childSelected = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        for (let i = 0; i < item.sub_menu.length; i++) {
          if (
            item.sub_menu[i].nombre_web == "ordenanzas" ||
            item.sub_menu[i].nombre_web == "ordenanzas-municipales"
          ) {
            childSelected = true;
          }
        }
        if (childSelected) {
          item.expand = true;
          return " background-color: #f5cd0b !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
        }
      }
      return "";
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          ("ordenanzas" == this.menuLateral[i].nombre_web ||
            "ordenanzas-municipales" == this.menuLateral[i].nombre_web)
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private descargarArchivo(url: string) {
    this.win.open(url, "_blank");
    return;
  }

  private formatFecha(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    // var anho_termino = fecha_termino.substring(0, 4);
    // var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
    // var dia_termino = fecha_termino.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
}
